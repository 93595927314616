<template>
  <div>
    <v-card class="mb-6 ml-6 mt-6 mr-2">
      <v-card-title> Liste des Administrateurs</v-card-title>
      <v-data-table
        :loading="isLoadingTables"
        loading-text="Loading..."
        hide-default-footer
        :headers="headers"
        :items="users"
        class="elevation-1"
        item-key="uid"
      >
        <template v-slot:item.creationDate="{ item }">
          {{ formatDate(item.creationDate) }}
        </template>
        <template v-slot:item.modifier="{ item }">
          <v-icon @click="resetPassword(item.email)">
            {{ icons.mdiSyncAlert }}
          </v-icon>
        </template>
        <template v-slot:item.supprimer="{ item }">
          <v-icon @click="suppressionAction(item)">
            {{ icons.mdiDeleteOutline }}
          </v-icon>
        </template>
      </v-data-table>
      <v-pagination
        v-if="showPagination"
        v-model="page"
        :length="pageCount"
        :total-visible="7"
      ></v-pagination>
    </v-card>
    <v-row justify="center">
      <v-dialog v-model="dialogDeleteUser" max-width="600">
        <v-card color="text-center">
          <v-card-text class="d-flex flex-column justify-center align-center">
            <v-avatar color="error" class="mt-10" icon size="50">
              <v-icon size="2rem" color="white">
                {{ icons.mdiAlertCircleOutline }}
              </v-icon>
            </v-avatar>
          </v-card-text>
          <v-card-text
            ><h2 style="color: black;">
              Es-tu sûr de Vouloir Désactiver Utilisateur De l'Administration
            </h2>
          </v-card-text>
          <v-card-text>
            <v-btn
              @click="disableUserLogin()"
              color="error"
              class="mt-4"
              style="background-color: #A8FE39; color: aliceblue;"
            >
              Confirmer
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import {
  mdiPlus,
  mdiDeleteOutline,
  mdiPencil,
  mdiDatabasePlusOutline,
  mdiAlertCircleOutline,
  mdiSyncAlert,
} from "@mdi/js";
import { getAllUserstByRole } from "@/views/qorani/services/services";
import Constant from "@/utils/constants";
import "firebase/auth";
import firebase from "firebase/compat/app";
import { updateProfile } from "firebase/auth";
export default {
  data() {
    return {
      list: {},
      pageCount: 0,
      page: 1,
      itemsPerPage: 10,
      showPagination: true,
      icons: {
        mdiAlertCircleOutline,
        mdiPlus,
        mdiDeleteOutline,
        mdiPencil,
        mdiDatabasePlusOutline,
        mdiSyncAlert,
      },
      users: [],
      isLoadingTables: false,
      idUserSupprimmer: null,
      mailUserSupprimmer: null,
      dialogDeleteUser: false,
      headers: [
        { text: "Email", value: "email" },
        { text: "Nom", value: "lastName" },
        { text: "Prénom", value: "firstName" },
        {
          text: "Réinitialiser Mot de Passe",
          value: "modifier",
          align: "center",
        },
        { text: "Désactiver Utilisateur", value: "supprimer", align: "center" },
      ],
    };
  },
  async created() {
    this.isLoadingTables = true;
    this.list = await getAllUserstByRole("ADMIN", 0);
    this.users = this.list.userList;
    this.pageCount = this.list.nbrTotalPage;
    this.isLoadingTables = false;
  },
  watch: {
    async page(newValue) {
      this.isLoadingTables = true;

      const responseVerset = await getAllUserstByRole("USER", newValue - 1);
      this.users = responseVerset.userList;

      this.isLoadingTables = false;
    },
  },
  methods: {
    async resetPassword(email) {
      try {
        await firebase.auth().sendPasswordResetEmail(email);
        alert(
          "E-mail de réinitialisation du mot de passe envoyé. Vérifiez votre boîte de réception ou votre dossier spam"
        );
      } catch (error) {
        alert(error.message);
      }
    },
    async disableUserLogin() {
      const response = await fetch(
        Constant.QORANI + `user/editUserRoleByUserId/${this.idUserSupprimmer}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      try {
        this.dialogDeleteUser = false;
        window.location.reload();
      } catch (error) {
        alert(
          "Une erreur est survenue lors de la suppression du juz, veuillez réessayez plus tard, Merci."
        );
        this.dialogDeleteUser = false;
      }
    },

    formatDate(creationDate) {
      return moment(new Date(creationDate.seconds * 1000)).format(
        "YYYY-MM-DD HH:mm"
      );
    },

    suppressionAction(item) {
      this.idUserSupprimmer = item.userId;
      this.dialogDeleteUser = true;
    },
  },
};
</script>
